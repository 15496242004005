import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { ClientNavigationState, useClientNavigationState, useHasNavigated } from '../context/clientNavigation'
// import createIsolatedHook from '../helpers/createIsolatedHook'

/**
 * hasNavigated flag is used to disable gemius hit event on initial load.
 * isTransitioning flag is used to disable gemius hit event during SPA navigation
 *
 * @param {String} gemiusId
 * @param {*} diff Use anything that is unique for diff (exp. entity id, gallery childId).
 */
const useGemius = (gemiusId, diff) => {
    const [hasNavigated] = useHasNavigated()
    const navigationState = useClientNavigationState()
    const router = useRouter()
    const lastPathnameRef = useRef(null)

    const gemiusIdRef = useRef(gemiusId)

    // useEffect that updates gemiusIdRef.current when gemiusId changes
    useEffect(() => {
        gemiusIdRef.current = gemiusId
    }, [gemiusId])

    useEffect(() => {
        if (
            !hasNavigated ||
            !gemiusIdRef.current ||
            !diff ||
            navigationState !== ClientNavigationState.READY ||
            lastPathnameRef.current === router.asPath
        ) {
            return
        }
        lastPathnameRef.current = router.asPath
        // window?.pp_gemius_hit?.(gemiusIdRef.current)
    }, [diff, hasNavigated, router.asPath, navigationState])
}

export function IsolatedUseGemius({ gemiusId, diff }) {
    useGemius(gemiusId, diff)
    return null
}
IsolatedUseGemius.propTypes = {
    gemiusId: PropTypes.string.isRequired,
    diff: PropTypes.string.isRequired
}

// export const IsolatedUseGemius = createIsolatedHook(useGemius)

export default useGemius
