// Colors must not be used directly in components, but they should be (re)assigned here instead

export const paletteDefault = {
    mainBackground: '#F6F6F6',
    // Brand colors
    brand: '#F15B24',
    netOrange: '#F15B24',
    netBlue: '#114A79',
    netGrey: '#666766',
    netRed: '#D90000',
    netGreyAlt: '#666766',
    primary: '#080808',
    poolPrimary: '#080808',
    // Black white and greys
    black: '#080808',
    grey900: '#111111',
    grey400: '#DCDCDC',
    grey300: '#EAEAEA',
    grey200: '#F2F2F2',
    grey100: '#F6F6F6',
    white: '#FFF',
    before: '#514A00',
    inputGrey: '#EAEAEA',
    greySelect: '#BFBFBF',
    contrast: '#FFF',
    light: '#F6F6F6',
    // Section colors
    danas: '#BC202F',
    sport: '#0059B2',
    hot: '#B74497',
    magazin: '#719427',
    webCaffe: '#DBA418',
    zena: '#BF2175',
    // Other colors
    accentYellow: '#FFE900',
    decorationOrange: '#EB4D13',
    decorationGrey: '#EEEEEE',
    disabledInput: '#888',
    inputError: '#F30808',
    errorBackground: '#FFEEEE',
    graphicDefault: '#EB4D13',
    graphicAlternative: '#EEE',
    borderDark: '#444',
    // Ads
    adBackground: '#F6F6F6',
    voyo: '#6A34FB',
    voyoCtaBg: '#f50f4d',
    // Development
    devWarning: '#81FF0D', // dev error visual notification color - stick you in the eye
    // specific purpose colors
    navigationShadow: '#EAEAEA',
    logoTextColors: '#666766'
}

export const paletteDark = {
    mainBackground: '#000',
    // Brand colors
    netRed: '#BC202F',
    netGreyAlt: '#FFF',
    primary: '#FFF',
    poolPrimary: '#080808',
    // Black white and greys
    grey400: '#666',
    grey300: '#555',
    grey200: '#444',
    grey100: '#333',
    contrast: '#000',
    light: '#333',
    // Ads
    adBackground: '#161616',
    // specific purpose colors
    navigationShadow: '#222',
    logoTextColors: '#fff'
}

export const paletteDanas = {
    brand: paletteDefault.danas,
    netBlue: paletteDefault.danas,
    netGrey: paletteDefault.danas,
    netRed: paletteDefault.danas
}
export const paletteDanasDark = {
    brand: paletteDefault.danas,
    netBlue: paletteDefault.danas,
    netGrey: paletteDefault.danas,
    netRed: paletteDefault.danas
}

export const getColors = palette => {
    // Configure global colors here
    const colors = {
        separator: palette.grey400,
        text: {
            default: palette.primary,
            inverted: palette.contrast,
            accent: palette.brand,
            placeholder: palette.netGrey,
            white: palette.white
        },
        shadow: {
            default: palette.primary
        },
        background: {
            mainDefault: palette.mainBackground,
            default: palette.contrast,
            light: palette.light,
            accent: palette.brand,
            input: palette.inputGrey,
            inverted: palette.primary
        },
        card: {
            label: {
                hover: palette.primary
            },
            title: {
                default: palette.primary
            },
            background: {
                default: palette.light
            }
        },
        button: {
            alpha: {
                text: {
                    default: palette.black,
                    hover: palette.brand,
                    disabled: palette.light
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    disabled: palette.light
                },
                border: {
                    default: palette.black,
                    hover: palette.brand,
                    inverted: palette.brand
                }
            },
            beta: {
                text: {
                    default: palette.white,
                    inverted: palette.primary,
                    invertedHover: palette.brand,
                    hover: palette.white,
                    disabled: palette.inputError
                },
                background: {
                    default: palette.brand,
                    hover: palette.brand,
                    inverted: palette.white,
                    disabled: palette.grey400
                }
            }
        },
        decoration: {
            default: palette.decorationOrange,
            alternative: palette.decorationGrey,
            yellow: palette.accentYellow,
            text: palette.black,
            before: palette.before,
            graphic: {
                default: palette.graphicDefault,
                alternative: palette.graphicAlternative
            }
        },
        link: {
            alpha: {
                text: {
                    default: palette.brand,
                    hover: palette.brand,
                    active: palette.netRed,
                    visited: palette.brand,
                    disabled: palette.light
                }
            }
        },
        survey: {
            background: palette.white,
            border: palette.grey400,
            check: {
                border: palette.grey300,
                background: {
                    default: palette.grey300,
                    checked: palette.brand
                },
                color: {
                    default: palette.grey300,
                    checked: palette.white
                }
            },
            input: {
                text: {
                    disabled: palette.disabledInput,
                    error: palette.inputError
                },
                background: {
                    disabled: palette.grey200,
                    error: palette.errorBackground
                },
                border: {
                    hover: palette.netGrey,
                    focus: palette.brand,
                    error: palette.inputError
                }
            }
        },
        social: {
            popUp: {
                text: palette.primary,
                background: palette.grey300
            }
        },
        category: {
            net: palette.brand,
            danas: palette.danas,
            sport: palette.sport,
            hot: palette.hot,
            magazin: palette.magazin,
            webCaffe: palette.webCaffe,
            webcafe: palette.webCaffe,
            fun: palette.accentYellow
        },
        media: {
            color: palette.white,
            background: palette.brand,
            net: palette.brand,
            danas: palette.danas,
            sport: palette.sport,
            hot: palette.hot,
            magazin: palette.magazin,
            webCaffe: palette.webCaffe,
            fun: palette.accentYellow
        },
        poll: {
            background: palette.grey100,
            color: palette.netGrey,
            item: {
                background: palette.white,
                color: {
                    default: palette.netGrey,
                    active: palette.poolPrimary
                }
            },
            alpha: {
                select: {
                    background: {
                        default: palette.grey200,
                        active: palette.brand
                    },
                    fill: {
                        default: palette.greySelect,
                        active: palette.white
                    },
                    color: {
                        default: palette.pollPrimary,
                        active: palette.brand
                    }
                },
                votes: {
                    background: {
                        default: palette.brand
                    }
                }
            },
            beta: {
                select: {
                    background: {
                        default: palette.white,
                        active: palette.brand
                    },
                    fill: {
                        default: palette.greySelect,
                        active: palette.white
                    },
                    color: {
                        default: palette.primary,
                        active: palette.white
                    }
                },
                votes: {
                    background: {
                        default: palette.brand
                    }
                }
            }
        },
        pill: {
            text: {
                default: palette.grey400,
                hover: palette.primary
            },
            background: {
                default: palette.contrast
            },
            border: {
                default: palette.grey400,
                hover: palette.primary
            }
        },
        border: {
            default: palette.grey400,
            light: palette.grey100,
            darker: palette.borderDark
        },
        error: {
            dev: {
                text: palette.primary,
                background: palette.devWarning
            }
        },
        search: {
            input: {
                background: palette.grey300,
                text: {
                    placeholder: palette.netGrey,
                    default: palette.primary,
                    focus: palette.primary
                }
            },
            highlight: palette.netBlue,
            button: {
                background: {
                    default: palette.brand,
                    hover: palette.netGrey
                },
                icon: {
                    default: palette.contrast,
                    hover: palette.contrast
                }
            }
        },
        special: {
            label: {
                default: palette.contrast,
                hover: palette.primary
            },
            title: {
                default: palette.contrast
            },
            lead: {
                default: palette.contrast
            },
            background: {
                default: palette.light
            }
        },
        breaking: {
            background: {
                default: palette.light,
                darker: palette.grey400
            }
        },
        header: {
            logo: {
                title: palette.netGreyAlt
            },
            background: palette.contrast
        },
        freemail: {
            textColor: {
                default: palette.primary,
                hover: palette.brand
            },
            background: {
                default: palette.contrast,
                hover: palette.contrast
            }
        },
        navigation: {
            menu: {
                background: palette.contrast,
                search: {
                    button: {
                        background: {
                            default: palette.brand,
                            hover: palette.netGrey
                        },
                        icon: {
                            default: palette.contrast,
                            hover: palette.contrast
                        }
                    },
                    input: {
                        background: palette.grey200,
                        text: {
                            default: palette.primary,
                            focus: palette.grey400
                        }
                    }
                },
                button: {
                    icon: {
                        default: palette.primary,
                        hover: palette.primary
                    },
                    background: {
                        default: palette.contrast,
                        hover: palette.grey400
                    }
                },
                item: {
                    default: {
                        default: palette.primary, // overridden by category logic in most cases
                        hover: palette.brand
                    },
                    footer: {
                        default: palette.primary,
                        hover: palette.brand
                    },
                    partner: {
                        default: palette.grey300,
                        hover: palette.primary
                    }
                },
                title: palette.netGrey,
                arrow: {
                    border: palette.grey300,
                    background: palette.contrast,
                    icon: palette.primary
                }
            },
            submenu: {
                background: palette.grey100
            }
        },

        footer: {
            background: palette.contrast,
            text: {
                primary: palette.primary,
                info: palette.primary,
                partner: palette.netGrey
            },
            separator: palette.netGrey
        },
        linker: {
            text: palette.primary,
            background: palette.contrast
        }
    }
    return colors
}
// needed for layout theme and shadows
export const colorsDefault = getColors(paletteDefault)
