import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { getOneElastic } from '@hmn/rtl-web-core/hooks'
import useDeviceType from '@hmn/rtl-web-core/hooks/layout/useDeviceType'
import { handleError, returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import { DynamicListalicaProizvoda } from '@hmn/rtl-net-ui/components/ListalicaProizvoda'
import { toRem } from '@hmn/rtl-net-ui/helpers' // @TODO: add prod ids here

import { EuIzboriAnketeWithData } from '../../izbori/2024-eu/EuIzboriAnkete'
import { EuIzboriKandidatiWithData } from '../../izbori/2024-eu/EuIzboriKandidati'
import { EuIzboriListeWithData } from '../../izbori/2024-eu/EuIzboriListe'
import { PredsjIzboriAnketeWithData } from '../../izbori/2024-predsjednicki/PredsjIzboriAnkete'
import { PredsjIzboriRezultatiWithData } from '../../izbori/2024-predsjednicki/PredsjIzboriRezultati'

const EU_ANKETE_IDS = ['6509b0e8-2285-11ef-a555-86671993babd', 'e973b748-24d8-11ef-bf85-d6076efa118f'] // DONE: add prod ids here
const EU_LISTE_IDS = ['e4a606cc-2284-11ef-a61f-86671993babd', 'ac8c9138-24d8-11ef-994f-52c5a02d7aa3'] // DONE: add prod ids here
const EU_KANDIDATI_IDS = ['3250d74e-2285-11ef-bfc7-86671993babd', 'ca6d3554-24d8-11ef-abeb-d638d7e5eb41'] // DONE: add prod ids here
const PREDSJ_ANKETE_IDS = ['34adb364-bb90-11ef-912f-72fcf00d7dfa', 'b34541cc-c457-11ef-88e7-b282ec61614a'] // DONE: add prod ids here
const PREDSJ_REZULTATI_IDS = ['0f13712a-bd2f-11ef-bdb4-9ec7cd506fba', 'c5416ff4-c457-11ef-b79d-829ff0affdfc'] // DONE: add prod ids here

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function EuIzboriWidgets({ id, landing }) {
    const [device] = useDeviceType()
    if (!device) {
        return null
    }

    if (EU_ANKETE_IDS.includes(id)) {
        return <EuIzboriAnketeWithData landing={landing} />
    }

    if (EU_LISTE_IDS.includes(id)) {
        return <EuIzboriListeWithData landing={landing} />
    }

    if (EU_KANDIDATI_IDS.includes(id)) {
        return <EuIzboriKandidatiWithData landing={landing} />
    }

    if (PREDSJ_ANKETE_IDS.includes(id)) {
        return <PredsjIzboriAnketeWithData landing={landing} />
    }

    if (PREDSJ_REZULTATI_IDS.includes(id)) {
        return <PredsjIzboriRezultatiWithData landing={landing} />
    }
}

EuIzboriWidgets.propTypes = {
    id: PropTypes.string.isRequired,
    landing: PropTypes.string
}

EuIzboriWidgets.defaultProps = {
    landing: '/danas/izbori-2024'
}

function WidgetProductGalleries({ position, listalica, logoImage, deviceProp, ...rest }) {
    const [device] = useDeviceType()

    if (!listalica) {
        return null
    }

    const { id: listalicaId } = listalica

    const isIzbori =
        EU_ANKETE_IDS.includes(listalicaId) ||
        EU_LISTE_IDS.includes(listalicaId) ||
        EU_KANDIDATI_IDS.includes(listalicaId) ||
        PREDSJ_ANKETE_IDS.includes(listalicaId) ||
        PREDSJ_REZULTATI_IDS.includes(listalicaId)

    if (isIzbori) {
        const { extended_attributes: extendedAttributes } = listalica
        const { product_galleries_disclaimer: heights, product_galleries_url: landingUrl } = extendedAttributes

        const mobileHeight = (heights && parseFloat(heights.split(':')[1])) || 'auto'
        const desktopHeight = (heights && parseFloat(heights.split(':')[0])) || 'auto'

        const widgetStyles = {
            minHeight: device === 'mobile' ? mobileHeight : desktopHeight,
            margin: '10px 0'
        }

        return (
            <Cell position={position} style={widgetStyles}>
                <EuIzboriWidgets id={listalicaId} landing={landingUrl} />
            </Cell>
        )
    }

    return (
        <Cell position={position} style={{ marginTop: toRem(10) }} data-upscore-zone="product-gallery">
            <DynamicListalicaProizvoda
                title={listalica?.title}
                items={listalica?.extended_attributes?.product_galleries_products}
                showLogo={!listalica?.extended_attributes?.product_galleries_show_title}
                logoImage={logoImage}
                titleUrl={listalica?.extended_attributes?.product_galleries_url}
                disclaimer={listalica?.extended_attributes?.product_galleries_disclaimer}
                isWidget
            />
        </Cell>
    )
}

export async function getData({ id }) {
    const { data: listalica } = await getOneElastic(`entity/${id}`, {
        preview: true
    }).catch(handleError(returnEmptyOn4xx))

    const logoImage = await (async () => {
        if (listalica?.image?.id) {
            const imageResponse = await getOneElastic(`image/${listalica.image.id}`).catch(
                handleError(returnEmptyOn4xx)
            )

            return imageResponse.data
        }

        return undefined
    })()

    return {
        listalica,
        logoImage
    }
}

WidgetProductGalleries.propTypes = {
    position: PropTypes.shape({}),
    listalica: PropTypes.shape({
        title: PropTypes.string,
        extended_attributes: PropTypes.shape({
            product_galleries_products: PropTypes.arrayOf(PropTypes.shape({})),
            product_galleries_show_title: PropTypes.bool,
            product_galleries_url: PropTypes.string,
            product_galleries_disclaimer: PropTypes.string
        }),
        image: PropTypes.shape({}),
        id: PropTypes.string
    }).isRequired,
    deviceProp: PropTypes.string.isRequired,
    logoImage: PropTypes.shape({})
}

WidgetProductGalleries.defaultProps = {
    position: {},
    logoImage: undefined
}

export default WidgetProductGalleries
