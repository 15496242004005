// import { toRem, toRems } from '../../../../../../../../helpers/theme'

import { isColor, toRem, toRems } from '../../../../../../../../helpers'

const style = ({ theme, variant, categoryColor, active, hasHref, isAmp }) => {
    const customColor = isColor(categoryColor) && categoryColor
    const menuPalette = theme.colors?.navigation?.menu?.item?.[variant] ||
        theme.colors?.navigation?.menu?.item?.default || {
            default: theme.colors.devWarning,
            hover: theme.colors.devWarning
        }

    const colorDefault = (active && customColor) || menuPalette.default
    const colorHover = (!active && customColor) || menuPalette.hover

    const config = {
        header: {
            md: {
                display: 'inline',
                color: {
                    default: (active && customColor) || menuPalette.default,
                    hover: customColor || menuPalette.hover
                },
                fontSize: toRem(20),
                lineHeight: toRem(25),
                height: toRem(25),
                margin: toRems([0, 10])
            },
            sm: {
                display: 'none'
            },
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: theme.typography.font.weight.bold,
            textTransform: 'uppercase'
        },
        navigation: {
            md: {
                display: 'block',
                color: {
                    default: (active && customColor) || menuPalette.default,
                    hover: (!active && customColor) || menuPalette.hover
                },
                fontSize: toRem(18),
                lineHeight: toRem(36)
            },
            sm: {
                display: 'block',
                color: {
                    default: (active && (customColor || theme.colors.brand)) || menuPalette.default,
                    hover: (!active && (customColor || theme.colors.brand)) || menuPalette.hover
                },
                fontSize: toRem(20),
                lineHeight: toRem(36)
            },
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: theme.typography.font.weight.bold,
            textTransform: 'uppercase'
        },

        footer: {
            md: {
                display: 'block',
                color: {
                    default: customColor || menuPalette.default,
                    hover: customColor || menuPalette.hover
                },
                fontSize: toRem(18),
                lineHeight: toRem(36)
            },
            sm: {
                display: 'block',
                color: {
                    default: customColor || menuPalette.default,
                    hover: customColor || menuPalette.hover
                },
                fontSize: toRem(20),
                lineHeight: toRem(36)
            },
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: theme.typography.font.weight.bold,
            textTransform: 'uppercase'
        },
        info: {
            display: 'inline-block',
            textTransform: 'initial',
            color: {
                default: theme.colors.footer?.text?.info || theme.colors.devWarning,
                hover: theme.colors.brand
            },
            fontSize: toRem(14),
            lineHeight: toRem(42),
            margin: toRems([0, 10, 0, 0]),
            fontWeight: theme.typography.font.weight.regular
        },
        partner: {
            display: 'inline-block',
            textTransform: 'initial',
            color: {
                default: theme.colors.footer?.text?.partner || theme.colors.devWarning,
                hover: theme.colors.brand
            },
            fontSize: toRem(14),
            lineHeight: toRem(25),
            margin: toRems([0, 10, 0, 0]),
            fontWeight: theme.typography.font.weight.regular
        }
    }
    const variantConfig = config[variant] || {}
    const variantConfigMd = variantConfig?.md || variantConfig || {}
    if (!variantConfigMd.color) {
        variantConfigMd.color = menuPalette
    }
    const variantConfigSm = variantConfig?.sm || variantConfig || {}
    if (!variantConfigSm.color) {
        variantConfigSm.color = menuPalette
    }
    return {
        '.primaryMenuItem': {
            transition: 'color 300ms',
            fontWeight: variantConfig.fontWeight,
            fontStyle: theme.typography.font.style.normal,
            fontFamily: variantConfig.fontFamily || theme.typography.fontFamily,
            fontSize: variantConfigSm.fontSize,
            lineHeight: variantConfigSm.lineHeight,
            margin: variantConfigSm.margin || toRem(0),
            color: colorDefault,
            display: variantConfigSm?.display || 'none',
            textTransform: variantConfig?.textTransform || 'initial',
            height: variantConfigSm?.height || 'auto',
            ...(!isAmp && {
                [theme.breakpoints.up('md')]: {
                    color: variantConfigMd?.color?.default || theme.colors.devWarning,
                    display: variantConfigMd?.display || 'none',
                    fontSize: variantConfigMd.fontSize,
                    margin: variantConfigMd.margin || toRem(0),
                    lineHeight: variantConfigMd.lineHeight,
                    height: variantConfigMd?.height || 'auto'
                }
            }),
            ...(hasHref && {
                '&:hover': {
                    color: colorHover,
                    ...(!isAmp && {
                        [theme.breakpoints.up('md')]: {
                            color: variantConfigMd?.color?.hover || theme.colors.devWarning
                        }
                    })
                }
            })
        }
    }
}
export default style
