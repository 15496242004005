import { Fragment, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { getWidgetListWithData } from '@hmn/rtl-web-core/helpers/category'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import { useListingInsertAds } from '@hmn/rtl-web-core/hooks/article/useAds'
import { IsolatedUseCategoryThirdParties } from '@hmn/rtl-web-core/hooks/category/useCategoryThirdParties'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import useCurrentPageNumber from '@hmn/rtl-web-core/hooks/useCurrentPageNumber'

import { insertAds as insertAdsConfig, staticAds } from '@hmn/rtl-net-ui/components/Ad/config/listing'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
// import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import { ArchiveDatePicker } from '@hmn/rtl-net-ui/components/Archive'
import {
    ArticleCard,
    articleCardSizeVariants,
    articleCardVariants
} from '@hmn/rtl-net-ui/components/Article/components/Card'
import { Button, netButtonVariants } from '@hmn/rtl-net-ui/components/Button'
import { ConditionalInsert } from '@hmn/rtl-net-ui/components/ConditionalInsert'
import { ArrowNetIcon, Icon } from '@hmn/rtl-net-ui/components/Icon'
import { imageRatioVariants } from '@hmn/rtl-net-ui/components/Image'
import { Pagination } from '@hmn/rtl-net-ui/components/Pagination'
import { RssAutodiscovery } from '@hmn/rtl-net-ui/components/RssAutodiscovery'
import { netSectionTitleSizeVariants, SectionTitle } from '@hmn/rtl-net-ui/components/SectionTitle'
import { imageDimensions } from '@hmn/rtl-net-ui/helpers'

import { BaseLayout } from '../../layouts'
import { LayoutBuilder } from '..'
import WidgetComponents from '../LayoutBuilder/components/WidgetComponents'
import ComponentType from '../LayoutBuilder/helpers/componentType'
import Seo from '../NetSeo.component'

const perPage = 50

const { halfpage1, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds

export function Category({
    category,
    categoryLayout,
    categoryEntities,
    datePickerData,
    isListing,
    isLayout,
    gemiusId,
    slugs,
    navigation,
    categoryColor
}) {
    const handleGFCRevoke = () => {
        window.Didomi.preferences.show()
    }

    const uid = useUIDSeed()

    const currentPage = useCurrentPageNumber()

    const widgetListWithData = useMemo(
        () => (isLayout ? getWidgetListWithData(categoryLayout, WidgetComponents, ComponentType) : null),
        [isLayout, categoryLayout]
    )
    const articles = categoryEntities

    useEntityTypeDispatch('category')

    const insertAds = useListingInsertAds({
        insertAdsConfig,
        AdSlotComponent: AdSlot,
        ColumnComponent: Column,
        maxAdWidth: 750
    })
    return (
        <BaseLayout navigationData={navigation} categoryColor={categoryColor}>
            <IsolatedUseCategoryThirdParties
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                homepageId={process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET}
                gemiusId={gemiusId || process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                node={category}
                nodeId={category.id}
                slugs={slugs}
                isListing={isListing}
                isZena={false}
            />
            <RssAutodiscovery sitemap={category?.sitemap} />
            <Seo item={category} page={currentPage} listItems={articles} widgetListWithData={widgetListWithData} />
            <>
                <Row
                    variant="category.main"
                    className={
                        ['Politika kolačića', 'Pravila o korištenju kolačića'].includes(category?.title) &&
                        'custom_table'
                    }>
                    {isLayout ? (
                        <Column variant="category.widgets">
                            {category?.title === 'Pravila privatnosti' && (
                                <Column variant="category.privacyBtn">
                                    <Button
                                        onClick={handleGFCRevoke}
                                        variant={netButtonVariants.BETA}
                                        heightMd={50}
                                        heightXs={43}>
                                        Klikni za promjenu postavki Pravila kolačića i privatnosti
                                    </Button>
                                </Column>
                            )}
                            <Media lessThan="lg">
                                <LayoutBuilder
                                    widgetListWithData={widgetListWithData?.mobile}
                                    // @NOTE: upscore request
                                    data-upscore-zone={
                                        category.id === process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET ? 'homepage' : ''
                                    }
                                    deviceProp="mobile"
                                />
                            </Media>
                            <Media greaterThan="md">
                                <LayoutBuilder
                                    widgetListWithData={widgetListWithData?.desktop}
                                    // @NOTE: upscore request
                                    data-upscore-zone={
                                        category.id === process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET ? 'homepage' : ''
                                    }
                                    deviceProp="desktop"
                                />
                            </Media>

                            {/* INFO: leave AdNow commentout for now */}
                            {/* {category.id === process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET ? <AdNow /> : null} */}

                            {category?.title !== 'Pravila privatnosti' && (
                                <Column variant="category.showMoreBtn">
                                    <Button
                                        variant={netButtonVariants.BETA}
                                        heightMd={50}
                                        heightXs={43}
                                        href={
                                            category?.title === 'Naslovnica'
                                                ? '/najnovije?stranica=1'
                                                : `${category?.uri}/najnovije?stranica=1`
                                        }
                                        iconComponent={
                                            <Icon icon={ArrowNetIcon} style={{ fontSize: 18 }} viewBox="0 0 20 20" />
                                        }>
                                        Vidi još članaka
                                    </Button>
                                </Column>
                            )}
                        </Column>
                    ) : (
                        <Column variant="category.listing">
                            <SectionTitle
                                title={category?.title}
                                sizeVariant={netSectionTitleSizeVariants['1x1']}
                                isListing
                                borderColor={category?.extended_attributes?.category_color}
                            />

                            <Media lessThan="md" scaleDown>
                                <ArchiveDatePicker
                                    slugs={slugs}
                                    data={datePickerData?.[0]}
                                    pageTitle={category?.title}
                                />
                            </Media>
                            {!!articles?.length &&
                                articles.map((item, index) => {
                                    const shouldBeLarge = index === 0 && currentPage < 2

                                    const imageSizesMd = shouldBeLarge ? 60 : 20
                                    const imageSizesSm = shouldBeLarge ? 90 : '110px'

                                    return (
                                        // @TODO: completely isolate all of inserted listing banner logic in a compoment and memoize there
                                        // look at the zena/pages/category as an example
                                        <Fragment key={uid(item?.id || index)}>
                                            <Column variant="category.listing.article" key={uid(item?.id || index)}>
                                                <Media lessThan="md" scaleDown>
                                                    <ArticleCard
                                                        item={item}
                                                        imageVariation={
                                                            shouldBeLarge
                                                                ? imageRatioVariants.CUSTOM_WIDE_SCREEN
                                                                : imageRatioVariants.CUSTOM_SQUARE
                                                        }
                                                        variant={
                                                            shouldBeLarge
                                                                ? articleCardVariants.ALPHA
                                                                : articleCardVariants.BETA
                                                        }
                                                        href={item?.sitemap?.href}
                                                        imageWidth={
                                                            index || currentPage !== 1
                                                                ? imageDimensions('listing', true)?.[1].width
                                                                : imageDimensions('listing', true)?.[0].width
                                                        }
                                                        imageHeight={
                                                            index || currentPage !== 1
                                                                ? imageDimensions('listing', true)?.[1].height
                                                                : imageDimensions('listing', true)?.[0].height
                                                        }
                                                        sizeVariant={
                                                            shouldBeLarge
                                                                ? articleCardSizeVariants['2x3']
                                                                : articleCardSizeVariants['1x6']
                                                        }
                                                        contentMarkerVariant={item?.type.toUpperCase()}
                                                        categoryColor={category?.extended_attributes?.category_color}
                                                        imagePreload={index < 2}
                                                        imageLazyLoad={index > 1}
                                                        imageSizesMd={imageSizesMd}
                                                        imageSizesSm={imageSizesSm}
                                                    />
                                                </Media>
                                                <Media greaterThan="sm" scaleDown>
                                                    <ArticleCard
                                                        item={item}
                                                        imageVariation={imageRatioVariants.CUSTOM_WIDE_SCREEN}
                                                        variant={
                                                            shouldBeLarge
                                                                ? articleCardVariants.ALPHA
                                                                : articleCardVariants.BETA
                                                        }
                                                        href={item?.sitemap?.href}
                                                        imageWidth={
                                                            index || currentPage !== 1
                                                                ? imageDimensions('listing')?.[1].width
                                                                : imageDimensions('listing')?.[0].width
                                                        }
                                                        imageHeight={
                                                            index || currentPage !== 1
                                                                ? imageDimensions('listing')?.[1].height
                                                                : imageDimensions('listing')?.[0].height
                                                        }
                                                        sizeVariant={articleCardSizeVariants['2x3']}
                                                        contentMarkerVariant={item?.type.toUpperCase()}
                                                        categoryColor={category?.extended_attributes?.category_color}
                                                        imagePreload={index < 2}
                                                        imageLazyLoad={index > 1}
                                                        imageSizesMd={imageSizesMd}
                                                        imageSizesSm={imageSizesSm}
                                                    />
                                                </Media>
                                            </Column>
                                            <ConditionalInsert
                                                insert={insertAds}
                                                currentIndex={index}
                                                id="ads_container_body"
                                            />
                                        </Fragment>
                                    )
                                })}
                            {articles?.[0]?.totalItems > 50 ? (
                                <Row variant="category.pagination">
                                    <Pagination
                                        pageTotal={(articles?.[0]?.totalItems || 0) / perPage}
                                        selected={currentPage}
                                        pageHref={`/${safelyJoinSlugs(slugs)}/najnovije`}
                                        paginationColor={category?.extended_attributes?.category_color}
                                        pageName={category?.title}
                                    />
                                </Row>
                            ) : null}
                        </Column>
                    )}
                    {isLayout ? null : (
                        <Column variant="category.aside">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                <Column variant="category.sidebarItem">
                                    <ArchiveDatePicker
                                        slugs={slugs}
                                        data={datePickerData?.[0]}
                                        pageTitle={category?.title}
                                    />
                                </Column>
                                <Column variant="category.ads.floating">
                                    <AdSlot {...halfpage1} id="ads_container_aside" />
                                </Column>
                            </Media>
                        </Column>
                    )}
                </Row>

                <Row variant="zeroDimensions">
                    <AdSlot {...outOfPage1} />
                    <AdSlot {...outOfPage2} />
                    <AdSlot {...outOfPage3} />
                    <AdSlot {...staticAds.anchor} />
                    <AdSlot {...interstitial} />
                </Row>
            </>
        </BaseLayout>
    )
}

Category.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sitemap: PropTypes.shape({}),
        title: PropTypes.string,
        uri: PropTypes.string,
        extended_attributes: PropTypes.shape({
            category_color: PropTypes.string
        })
    }).isRequired,
    categoryLayout: PropTypes.shape({}),
    categoryEntities: PropTypes.arrayOf(PropTypes.shape({})),
    datePickerData: PropTypes.arrayOf(PropTypes.shape({})),
    isLayout: PropTypes.bool.isRequired,
    isListing: PropTypes.bool.isRequired,
    slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
    gemiusId: PropTypes.string.isRequired,
    navigation: PropTypes.shape({}).isRequired,
    categoryColor: PropTypes.string
}

Category.defaultProps = {
    categoryLayout: undefined,
    categoryEntities: undefined,
    datePickerData: undefined,
    categoryColor: undefined
}
