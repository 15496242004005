import { useMemo } from 'react'

type NavigationDataSingle = {
    title: string
    href: string
    color?: string
    image?: string
    className?: string
}

type TopNavigationData = NavigationDataSingle & { items?: NavigationDataSingle[] }

type NavigationData = {
    items: Record<string, TopNavigationData | NavigationDataSingle[]>
    mapping: Record<string, string[]>
    options: {
        createSubmenu: boolean
        keepNavigationsFlat: string[]
    }
}

const useNavigation = (
    navigationData: NavigationData,
    name = 'main',
    resultOverrides?: {
        limitMapped: number
        append: TopNavigationData[]
        insert: [number, TopNavigationData][]
    }
) => {
    const result = useMemo(() => {
        if (!navigationData) {
            return []
        }
        const { limitMapped, append, insert } = resultOverrides || { limitMapped: 10, append: [], insert: [] }
        const { mapping, items, options = { createSubmenu: false, keepNavigationsFlat: [] } } = navigationData
        const checkMappingForValidNames = () =>
            (Array.isArray(name) ? name : [name]).some(n => !!navigationData?.mapping?.[n])
        if (!mapping || !checkMappingForValidNames() || !items) {
            return []
        }
        const checkNestedNavigation = (n: string) =>
            !!options?.createSubmenu &&
            !navigationData?.mapping?.[n]?.some(nav => options?.keepNavigationsFlat?.includes(nav))

        const itemsParsed = checkNestedNavigation(name)
            ? mapping[name].map(menuName => items[menuName]).filter(Boolean)
            : mapping[name]
                  .reduce(
                      (acc, menuName) => [...acc, ...(items[menuName] as NavigationDataSingle[])],
                      [] as NavigationDataSingle[]
                  )
                  .filter(Boolean)

        const slicedData = itemsParsed.slice(0, limitMapped).concat(append)
        insert?.forEach(([index, item]) => slicedData.splice(index, 0, item))
        const checkItem = (item: NavigationDataSingle | TopNavigationData) =>
            typeof item !== 'undefined' && ((!!item.title && item.href) || !!item.image)
        return slicedData.filter(item =>
            Array.isArray(item) ? item.length > 0 && item.some(checkItem) : checkItem(item)
        )
    }, [navigationData, name])

    return result
}
export default useNavigation
