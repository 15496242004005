import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

import slugify from '../helpers/slugify'
import styles from '../styles/predsjRezultati.style'
import CandidateView from './CandidateView.component'
import { GradoviMap } from './GradoviMap'
import { HrvatskaMap } from './HrvatskaMap'
import { usePredsjednickiIzboriContext } from './PredsjIzboriContext'
import { resultsPropTypes } from './resultsPropTypes'

/** Root styled container that includes all the .izbori styles */
const IzboriLayout = styled.div(props => ({ ...styles(props) }))

/** Simple tab component */
function Tab({ tab, label, activeTab, handleTabSwitch }) {
    return (
        <div
            role="tab"
            tabIndex={0}
            className={`izbori__tab ${activeTab === tab ? 'is_active' : ''}`}
            onClick={() => handleTabSwitch(tab)}
            onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleTabSwitch(tab)
                }
            }}>
            {label}
        </div>
    )
}

Tab.propTypes = {
    tab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabSwitch: PropTypes.func.isRequired
}

/** Helper to capitalize each word */
const capitalizeEachWord = str => str.replace(/\b\w/g, char => char.toUpperCase())

/** Main component */
export function PredsjIzboriRezultati({ landing, countiesData, citiesData }) {
    // Local state
    const [activeTab, setActiveTab] = useState('ukupno')
    const [activeCounty, setActiveCounty] = useState(null)
    const [selectedCounty, setSelectedCounty] = useState(null)
    const [activeCity, setActiveCity] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)

    const handleTabSwitch = useCallback(tab => {
        setActiveTab(tab)
        // Reset selections if switching back to ukupno or different tab
        if (tab === 'ukupno') {
            setActiveCounty(null)
            setActiveCity(null)
            setSelectedCounty(null)
            setSelectedCity(null)
        } else {
            setActiveCity(null)
            setSelectedCity(null)
        }
    }, [])

    const handleCountyClick = useCallback(
        countyId => {
            if (activeTab === 'ukupno') {
                setActiveTab('counties')
            }
            setActiveCounty(countyId)
            const county = countiesData?.jedinica.find(item => `county-${item.sifra}` === countyId)
            setSelectedCounty(county)
            setSelectedCity(null)
        },
        [activeTab, countiesData]
    )

    const handleCityClick = useCallback(
        cityId => {
            setActiveTab('cities')
            setActiveCity(cityId)
            const city = citiesData?.jedinica.find(item => `city-${item.sifra}` === cityId)
            setSelectedCity(city)
            setActiveCounty(null)
            setSelectedCounty(null)
        },
        [citiesData]
    )

    // Prepare candidate data for whichever tab is active
    const candidates = useMemo(() => {
        const formatCandidates = list =>
            (list || []).map(candidate => ({
                ...candidate,
                slug: slugify(candidate.naziv),
                formattedGlasova: candidate.glasova.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                naziv: capitalizeEachWord(candidate.naziv.toLowerCase())
            }))

        // City-level data
        if (activeTab === 'cities' && selectedCity?.lista) {
            return formatCandidates(selectedCity.lista)
        }
        // County-level data
        if (activeTab === 'counties' && selectedCounty?.lista) {
            return formatCandidates(selectedCounty.lista)
        }
        // Fallback: overall (use the first item from countiesData as "total" data)
        return formatCandidates(countiesData?.jedinica?.[0]?.lista || [])
    }, [activeTab, selectedCity, selectedCounty, countiesData])

    // Top two candidates
    const favorites = useMemo(() => candidates.slice(0, 2), [candidates])

    // Head content with DIP rezultati
    const headContent = (
        <>
            <Image
                src="/images/izbori/pr-izbori-logo-2025.png"
                width="100"
                height="42"
                alt="Predsjednički izbori rezultati 2025"
                className="izbori__head_img"
            />
            <div className="izbori__head_info">
                <p className="title">DIP rezultati</p>
                <p className="source">
                    <span>
                        Obrađeno = <strong>{countiesData?.bmPosto}%</strong>
                    </span>
                </p>
            </div>
        </>
    )

    return (
        <IzboriLayout className={landing ? 'izbori' : 'izbori is_landing'}>
            {landing ? (
                <Link href={landing} target="_blank" title="Predsjednički izbori 2025" className="izbori__head">
                    {headContent}
                </Link>
            ) : (
                <div className="izbori__head">{headContent}</div>
            )}
            {/* Main interactive layout */}
            <div className="izbori__interactive">
                {/* Candidate cards */}
                {favorites.map(candidate => (
                    <CandidateView
                        key={candidate.jedinstvenaSifra}
                        candidate={candidate}
                        isSmall={false}
                        hasVoteCount
                    />
                ))}

                {/* Map section */}
                <div className="izbori__map">
                    <div className="izbori__tabs">
                        <Tab tab="ukupno" label="Ukupno" activeTab={activeTab} handleTabSwitch={handleTabSwitch} />
                        <Tab tab="cities" label="Gradovi" activeTab={activeTab} handleTabSwitch={handleTabSwitch} />
                        <Tab tab="counties" label="Županije" activeTab={activeTab} handleTabSwitch={handleTabSwitch} />
                    </div>

                    <div className="izbori__selection_title">
                        {selectedCity?.naziv || selectedCounty?.naziv || 'Cijela Hrvatska'}
                    </div>

                    <div className={`izbori__places is_${activeTab}`}>
                        <HrvatskaMap activeCounty={activeCounty} onCountyClick={handleCountyClick} />
                        {activeTab === 'cities' && <GradoviMap activeCity={activeCity} onCityClick={handleCityClick} />}
                    </div>
                </div>
            </div>
        </IzboriLayout>
    )
}

PredsjIzboriRezultati.propTypes = {
    landing: PropTypes.string,
    countiesData: resultsPropTypes.isRequired,
    citiesData: resultsPropTypes.isRequired
}

PredsjIzboriRezultati.defaultProps = {
    landing: ''
}

/** If you fetch results externally and want to wrap them, you can keep this or remove if not needed */
export function PredsjIzboriRezultatiWithData({ requestEnabled, landing }) {
    const contextResults = usePredsjednickiIzboriContext()
    const countiesData = contextResults.results?.counties || null
    const citiesData = contextResults.results?.cities || null

    if (!countiesData?.jedinica?.length || !citiesData?.jedinica?.length) {
        return null
    }

    return <PredsjIzboriRezultati landing={landing} countiesData={countiesData} citiesData={citiesData} />
}

PredsjIzboriRezultatiWithData.propTypes = {
    requestEnabled: PropTypes.bool,
    landing: PropTypes.string.isRequired
}

PredsjIzboriRezultatiWithData.defaultProps = {
    requestEnabled: true
}
