import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import slugify from '../helpers/slugify'

function CandidateView({ isSmall = false, candidate, hasVoteCount = false }) {
    return (
        <div
            className={clsx('izbori__card', `is_${slugify(candidate.naziv)}`, isSmall ? 'is_small' : 'is_big')}
            key={candidate.jedinstvenaSifra}
            title={candidate.naziv}>
            <div className={`izbori__card_img is_${slugify(candidate.naziv)}`}>
                <div className="izbori__card_perc">
                    {candidate.posto}
                    <small>%</small>
                </div>
                {hasVoteCount && (
                    <div className="izbori__card_votes">
                        <strong>{candidate.formattedGlasova}</strong> glasova
                    </div>
                )}
                <img
                    src={`/images/izbori/pr_candidates/2krug/${candidate.jedinstvenaSifra}.png`}
                    width="250"
                    height="280"
                    alt={candidate.naziv}
                    className="izbori__image"
                />
            </div>
            <div className="izbori__card_info">{candidate.naziv}</div>
        </div>
    )
}

CandidateView.propTypes = {
    isSmall: PropTypes.bool,
    hasVoteCount: PropTypes.bool,
    candidate: PropTypes.oneOfType([
        PropTypes.shape({
            formattedPosto: PropTypes.string,
            posto: PropTypes.string,
            jedinstvenaSifra: PropTypes.number,
            naziv: PropTypes.string
        }),
        PropTypes.shape({
            formattedPosto: PropTypes.string,
            posto: PropTypes.string,
            jedinstvenaSifra: PropTypes.number,
            naziv: PropTypes.string,
            formattedGlasova: PropTypes.string,
            glasova: PropTypes.string
        })
    ]).isRequired
}

export default CandidateView
