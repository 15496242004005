const styles = ({ theme, partiesList }) => ({
    '&.izbori': {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxWidth: '1160px',
        margin: '0 -16px',
        padding: '0',
        gridColumnStart: 1,
        gridColumnEnd: 25,

        '@media (min-width: 768px)': {
            width: '100%',
            margin: '0 auto',
            borderRadius: '10px 10px 0 0'
        },

        '&.is_landing': {
            margin: '0',
            maxWidth: '100%'
        },

        '.izbori': {
            '&__head': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                background: 'linear-gradient(45deg, rgba(14,99,173,1) 0%, rgba(18,152,242,1) 100%)',

                '@media (min-width: 768px)': {
                    padding: '8px'
                },

                '&_img': {
                    width: '80px',
                    height: 'auto',

                    '@media (min-width: 768px)': {
                        width: '100px',
                        height: 'auto'
                    }
                },

                '&_info': {
                    textAlign: 'right',

                    '@media (min-width: 768px)': {
                        textAlign: 'right'
                    },

                    '.title, .source': {
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '18px',
                        letterSpacing: '-0.03em',
                        color: '#FFFFFF',
                        textTransform: 'uppercase',
                        fontStyle: 'normal',

                        '@media (min-width: 768px)': {
                            fontSize: '28px',
                            lineHeight: '17px',

                            '&.source': {
                                marginBottom: '-6px',
                                paddingTop: '4px'
                            }
                        }
                    },

                    '.source': {
                        fontSize: '12px',
                        textTransform: 'none',

                        span: {
                            display: 'inline-block',
                            paddingLeft: '8px'
                        }
                    }
                }
            },

            '&__interactive': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
                padding: '8px',

                '@media (min-width: 768px)': {
                    padding: '0'
                }
            },

            '&__map': {
                // flex: '0 0 calc(50% - 4px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px 16px 0',

                '@media (min-width: 768px)': {
                    flex: '0 0 calc(54% - 8px)',
                    margin: '8px 0',
                    border: '3px solid rgba(8, 8, 8, 0.12)',
                    borderRadius: '10px',
                    padding: '16px 40px 0'
                }
            },

            '&__selection_title': {
                padding: '8px 0',
                fontWeight: 700,
                fontSize: '18px',
                letterSpacing: '-0.03em',
                color: '#080808',
                textTransform: 'uppercase',
                textAlign: 'center',

                '@media (min-width: 768px)': {
                    padding: '8px 0 16px',
                    fontSize: '24px'
                }
            },

            '&__places': {
                position: 'relative',

                '.CityMap, .CountyMap': {
                    width: '100%',
                    height: 'auto',

                    path: {
                        cursor: 'pointer',
                        transition: 'fill 0.3s',

                        '&:hover': {
                            fill: '#8CC0EF'
                        },

                        '&.is_active': {
                            fill: '#1188DE'
                        }
                    }
                },

                '.CountyMap path': {
                    transition: 'stroke 0.3s ease-in-out, fill 0.3s ease-in-out'
                },

                '&.is_cities .CountyMap': {
                    path: {
                        stroke: '#565656'
                    },
                    'path.is_active': {
                        fill: '#0E6EAF'
                    }
                },

                '.CountyMap': {
                    g: {
                        cursor: 'pointer',
                        fill: 'rgba(8, 8, 8, 0.12)'
                    },
                    'g:hover': {
                        '> text': {},
                        '> rect': {
                            fill: '#8CC0EF'
                            // fill: 'rgba(8, 8, 8, 0.2)'
                        }
                    },
                    'g.is_active': {
                        '> text': {
                            fill: '#fff',
                            fontWeight: '700',
                            textAlign: 'center'
                        },
                        '> rect': {
                            fill: 'rgba(8, 8, 8, 0.8)'
                        }
                    }
                },

                '.CityMap': {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: 'auto',

                    text: {
                        cursor: 'pointer'
                    },

                    circle: {
                        stroke: 'rgba(0,0,0,0.1)',
                        strokeWidth: '20px'
                    },

                    'g:hover > *': {
                        fill: '#4FB8FF'
                    },

                    'g.is_active > *': {
                        fill: '#4FB8FF',
                        fontWeight: '700'
                    }
                }
            },

            '&__tabs': {
                display: 'flex',
                border: '2px solid #1188DE',
                borderRadius: '50px',
                overflow: 'hidden',
                width: '300px',
                height: '32px',
                position: 'relative',
                '@media (min-width: 768px)': {
                    width: '336px',
                    height: '36px'
                },

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 'calc(100% / 3)',
                    height: '100%',
                    background: '#1188DE',
                    transition: 'transform 0.3s ease-in-out',
                    zIndex: 0 /* Behind text */
                },

                /* Sliding logic based on active tab */
                '&:has(.izbori__tab:nth-child(1).is_active)::before': {
                    transform: 'translateX(0%)'
                },

                '&:has(.izbori__tab:nth-child(2).is_active)::before': {
                    transform: 'translateX(100%)'
                },

                '&:has(.izbori__tab:nth-child(3).is_active)::before': {
                    transform: 'translateX(200%)'
                }
            },

            '&__tab': {
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '18px',
                color: '#1188DE',
                cursor: 'pointer',
                borderRight: '1px solid #1188DE',
                position: 'relative',
                zIndex: 1,
                transition: 'color 0.3s, font-weight 0.3s',

                '&:hover': {
                    backgroundColor: 'rgba(130, 180, 255, 0.5)'
                },

                '&.is_active': {
                    color: '#fff',
                    fontWeight: 'bold'
                }
            },

            '&__card': {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
                flex: '0 0 calc(50% - 4px)',

                '&:hover': {},

                '@media (min-width: 768px)': {
                    flex: '0 0 calc(23% - 4px)'
                }
            },

            '&__card_img': {
                position: 'relative',
                width: '100%',
                height: '280px',
                borderRadius: '6px',
                overflow: 'hidden',
                transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',

                '@media (min-width: 768px)': {
                    height: '100%',
                    margin: '8px 0',
                    borderRadius: '10px'
                },

                '&.is_zoran-milanovi': {
                    backgroundColor: '#FE3641'
                },

                '&.is_dragan-primorac': {
                    backgroundColor: '#00BFF8'
                },

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    opacity: 0.5,
                    backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)'
                },

                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '33%',
                    opacity: 0.5,
                    backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)'
                },

                '&:hover': {},

                '> img': {
                    position: 'absolute',
                    maxWidth: 'inherit',
                    height: 'auto'
                },

                '&.is_zoran-milanovi > img': {
                    bottom: '-195px',
                    left: '-99px',
                    width: '380px',

                    '@media (min-width: 768px)': {
                        bottom: '-75px',
                        left: 'calc(50% - 243px)',
                        width: '486px'
                    }
                },

                '&.is_dragan-primorac > img': {
                    width: '317px',
                    bottom: '-131px',
                    left: '-64px',

                    '@media (min-width: 768px)': {
                        width: '446px',
                        bottom: '-41px',
                        left: 'calc(50% - 206px)'
                    }
                }
            },

            '&__card:nth-of-type(2)': {
                '@media (min-width: 768px)': {
                    order: 3
                }
            },

            '&__card_perc, &__card_votes': {
                position: 'absolute',
                bottom: '25px',
                left: '0',
                zIndex: 1,
                width: '100%',
                fontSize: '50px',
                fontWeight: '700',
                letterSpacing: '-0.03em',
                color: '#FFFFFF',
                lineHeight: '1',
                textShadow: '4px 4px 4px rgba(0, 0, 0, 0.29), 2px -1px 3px rgba(0, 0, 0, 0.5)',

                '@media (min-width: 768px)': {
                    top: '40px',
                    bottom: 'auto',
                    fontSize: '80px'
                },

                small: {
                    fontSize: '60%',
                    fontWeight: '800'
                }
            },

            '&__card_votes': {
                bottom: '10px',
                fontSize: '14px',
                fontWeight: '400',

                '@media (min-width: 768px)': {
                    top: '125px',
                    fontSize: '24px'
                }
            },

            '&__card_info': {
                position: 'absolute',
                top: '3px',
                width: '100%',
                marginTop: '8px',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '100%',
                letterSpacing: '-0.03em',
                color: '#fff',
                textAlign: 'center',
                textTransform: 'capitalize',
                textShadow: '4px 4px 4px rgba(0, 0, 0, 0.29), 2px -1px 3px rgba(0, 0, 0, 0.5)',

                '@media (min-width: 768px)': {
                    top: 'auto',
                    bottom: '40px',
                    fontSize: '32px'
                }
            }
        }
    }
})

export default styles
