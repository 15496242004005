import { toRem, toRems } from '../../helpers/theme'

const style = ({ theme, variant }) => {
    const { panel } = theme.global.navigation
    const config = {
        header: {
            height: {
                default: toRem(theme.global.header.height.md.open),
                xs: toRem(theme.global.header.height.xs.open)
            }
        },
        maxWidth: {
            lg: toRem(panel.maxWidth.lg - 80),
            md: toRem(panel.maxWidth.md),
            sm: toRem(panel.maxWidth.sm),
            xs: toRem(panel.maxWidth.xs)
        }
    }

    return {
        pointerEvents: 'none',
        position: variant === 'navigation' ? 'absolute' : 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: theme.zIndex.navigationMobile,
        [theme.breakpoints.up('md')]: {
            zIndex: theme.zIndex.navigationDesktop
        },
        '.navigation': {
            pointerEvents: 'all',
            zIndex: theme.zIndex.navigationMobile,
            height: variant === 'navigation' ? '100vh' : 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflow: 'visible',
            // maxWidth: toRem(310),
            margin: variant === 'navigation' ? 'auto' : 0,
            background: theme.colors.navigation?.menu?.background || theme.colors.devWarning,
            [theme.breakpoints.up('md')]: {
                overflow: 'hidden',
                margin: 'auto',
                alignItems: variant === 'navigation' ? 'center' : 'flex-start',
                zIndex: theme.zIndex.navigationDesktop,
                padding: variant === 'navigation' ? toRems([90, 35, 20, 25]) : toRems([40, 35, 0, 25]),
                maxWidth: config.maxWidth.lg,
                height: 'auto'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: toRem(25)
            },

            [theme.breakpoints.up('xl')]: {
                paddingLeft: toRem(15)
            },
            // before: 69720 after: 67635
            '&_header': {
                display: 'flex',
                width: '100%',
                flex: '0 0 auto',
                height: config.header.height.xs,
                maxWidth: `calc(100% - ${toRem(theme.global.header?.height?.xs?.open || 50)})`,

                [theme.breakpoints.up('sm')]: {
                    maxWidth: `calc(100vw - ${toRem(theme.global.header?.height?.xs?.open || 50)})`
                },
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                }
            },
            '&_search': {
                flex: '1 1 100%',
                background: 'yellow'
            },
            '&_button': {
                marginRight: toRem(-(theme.global.header?.height?.xs?.open || 50))
            },
            '&_content': {
                position: 'relative',
                display: 'flex',
                padding: variant === 'navigation' ? toRems([20, 30, 20, 30]) : toRems([20, 30, 0, 30]),

                flex: variant === 'navigation' ? '1 1 100%' : '0 0 auto',
                overflowX: 'hidden',
                overflowY: variant === 'navigation' ? 'scroll' : 'hidden',
                direction: 'rtl',
                '& > *': {
                    direction: 'ltr'
                },
                '&::-webkit-scrollbar ': {
                    display: 'none'
                },

                width: variant === 'navigation' ? 'auto' : '100%',
                alignItems: 'flex-end',
                boxSizing: 'content-box',
                flexDirection: 'column',
                maxWidth: variant === 'navigation' ? config.maxWidth.xs : `calc( 100vw - ${toRem(60)} )`,
                [theme.breakpoints.down('sm')]: {
                    alignSelf: 'stretch'
                },
                [theme.breakpoints.up('sm')]: {
                    maxWidth: variant === 'navigation' ? config.maxWidth.sm : `calc( 100vw - ${toRem(60)} )`
                },
                [theme.breakpoints.up('md')]: {
                    overflowY: 'hidden',
                    alignItems: 'center',
                    maxWidth: config.maxWidth.md,
                    padding: 0,
                    width: '100%'
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: config.maxWidth.lg,
                    flexDirection: 'row-reverse'
                },
                '&_overlay': {
                    display: variant === 'navigation' ? 'block' : 'none',
                    width: '100%',
                    height: toRem(30),
                    marginTop: toRem(-30),
                    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${
                        theme.colors.navigation?.menu?.background || theme.colors.devWarning
                    } 100%)`,
                    zIndex: theme.zIndex.navigationMobile,

                    [theme.breakpoints.up('mf')]: {
                        display: 'none'
                    }
                }
            },
            '&_menu': {
                flex: '1 0 auto',
                marginBottom: toRem(30),

                [theme.breakpoints.up('md')]: {
                    overflow: 'auto',
                    flex: '1 1 100%',
                    width: '100%'
                }
            },
            // '&_ugc': {
            //     flex: '0 0 auto',
            //     borderTop: `${toRem(1)} solid ${
            //         (variant === 'navigation' ? theme.colors.border?.default : theme.colors.border?.darker) ||
            //         theme.colors.devWarning
            //     }`,
            //     [theme.breakpoints.down('md')]: {
            //         borderBottom: `${toRem(1)} solid ${
            //             (variant === 'navigation' ? theme.colors.border?.default : theme.colors.border?.darker) ||
            //             theme.colors.devWarning
            //         }`
            //     },
            //     flexDirection: 'row',
            //     width: '100%',
            //     [theme.breakpoints.up('lg')]: {
            //         borderTop: 'none',
            //         width: toRem(300),
            //         flexDirection: 'column',
            //         marginLeft: toRem(30)
            //     },

            //     [theme.breakpoints.only('md')]: {
            //         width: '100%',
            //         justifyContent: 'center',
            //         padding: toRems([20, 10, 0]),
            //         margin: 0,
            //         background: 'none'
            //     },

            //     [theme.breakpoints.down('sm')]: {
            //         padding: variant === 'navigation' ? toRems([20, 0, 30]) : toRems([0, 0])
            //     }
            // },
            '&_footer': {
                background: theme.colors.navigation?.menu?.background || theme.colors.devWarning,
                width: '100%',
                borderBottom: `${toRem(1)} solid ${
                    (variant === 'navigation' ? theme.colors.border?.default : theme.colors.border?.darker) ||
                    theme.colors.devWarning
                }`,
                zIndex: theme.zIndex.navigationMobile,

                [theme.breakpoints.up('md')]: {
                    border: 'none'
                }
            }
        }
    }
}

export default style
