/* eslint-disable @typescript-eslint/naming-convention */

import { TaxonSitemap } from '../../next/data-fetching/elasticsearch'
import { MergeTypes } from '../helpers/MergeTypes'
import { ProcessedEntityMetadata, ProcessedStructuredData } from '../seo'

export type AuthorTaxon = {
    id: string
    title: string
    slug: string
    author_id: string
    content_taxonId: string
}
export type EntityAutor = {
    name: string
    url: string
    image: string
}

export type EntityElastic = {
    id: string
    type: EntityType
    structuredData: ProcessedStructuredData
    metadata: ProcessedEntityMetadata
    body: string
    title: string
    name: string
    authors?: EntityAutor[]
    taxons?: {
        [key: string]: any
        authors?: AuthorTaxon[]
    }
    extended_attributes?: {
        show_author_initials: boolean
        enable_comments: boolean
        display_inline_related_content: boolean
        display_ads: boolean
        display_third_party_content: boolean
        google_title: string
        show_author_info: boolean

        live_active: boolean
        live_location: string
        live_coverage_start_time: Date
        live_coverage_end_time: Date
        live_score: string
        live_guestteam: string
        live_hometeam: string
        live_competition: string

        isNsfw?: boolean

        [key: string]: any
    }
    title_original?: string
    year?: string
    short_description?: string
    [key: string]: any
}

/**
 * @Note Might be wrong, recheck with API response
 */
export type PublishedState = 'published' | 'unpublished' | 'scheduled'

export type Color = {
    percentage: number
    hex: string
}
export type ImageElastic = {
    original_file_type: string
    updated_at: string
    original_url: string
    name: string
    original_aspect_ratio: number
    id: string
    state: string
    colors: Color[]
}

export interface SitemapNode {
    id: string
    title: string
    href: string
    categoryColor: string
    gemiusId: string
}
export type ArticleEntityElastic = MergeTypes<
    EntityElastic,
    {
        image: ImageElastic
        author: AuthorTaxon
        body: string
        owned_by_user_profile_id: string
        title: string
        lead: string
        content: string
        state: PublishedState
        sitemap: {
            href: string
            node: SitemapNode
        }
        type: 'article'
    }
>
export type VoyoSlide = {
    image: {
        id: string
    }
    title: string
    active: boolean
    url?: string
    subtitle?: string
    show_from: string
    show_until: string
}
type VoyoSliderExtendedAttributes = {
    play_widget_slides: VoyoSlide[]
    play_widget_button_title: string
    play_widget_button_url: string
}
export type VoyoSliderElastic = MergeTypes<
    EntityElastic,
    {
        image: {
            id: string
        }
        extended_attributes: VoyoSliderExtendedAttributes
    }
>

//  {
//     id: 'b62ccb82-c5af-11ee-a7fb-a6a3e3a01370',
//     type: 'image',
//     created_at: 2024-02-07T11:54:56.000Z,
//     created_by_id: '265bbe86-c7e2-11ec-89cd-be65cb7ca402',
//     original_file_type: 'image/jpeg',
//     original_width: '1152',
//     original_height: '2048',
//     name: 'Oporba, Sabor',
//     owned_by_user_id: '265bbe86-c7e2-11ec-89cd-be65cb7ca402',
//     owned_by_user_profile_id: '18f93b2a-6362-11ee-b2ce-0ee8f36581c7',
//     owned_by_client_id: '3431e464-9083-11eb-9498-42a57d4254eb',
//     slug: 'oporba-sabor',
//     state: 'editing',
//     updated_at: 2024-02-07T11:55:12.000Z,
//     updated_by_id: '265bbe86-c7e2-11ec-89cd-be65cb7ca402',
//     '@timestamp': '2025-01-24T05:06:20+00:00',
//     author: {
//       id: '18f93b2a-6362-11ee-b2ce-0ee8f36581c7',
//       display_name: 'dunja.stankovic',
//       state: 'published'
//     },
//     content: 'Oporba, Sabor ',
//     extended_attributes: { source: 'RTL' },
//     colors: [ [Object], [Object], [Object], [Object], [Object], [Object] ],
//     source: 'RTL',
//     original_url: 'https://d19p4plxg0u3gz.cloudfront.net/b62ccb82-c5af-11ee-a7fb-a6a3e3a01370/original.jpeg',
//     original_aspect_ratio: 0.5625
//   },
export type ImageEntityElastic = MergeTypes<MergeTypes<EntityElastic, ImageElastic>, { type: 'image' }>

// {
//     extended_attributes: {
//       enable_comments: true,
//       display_ads: true,
//       subtitle: 'REAKCIJE NAKON GLASOVANJA'
//     },
//     image: {
//       id: '3334ce06-c5b8-11ee-b73c-120367c4e3dd',
//       original_file_type: 'image/jpeg',
//       name: 'screenshot2c08f364-c5b8-11ee-a1a0-a6a3e3a01370.jpeg',
//       state: 'editing',
//       updated_at: '2024-02-07T12:55:56+00:00',
//       colors: [Array],
//       original_url: 'https://d19p4plxg0u3gz.cloudfront.net/3334ce06-c5b8-11ee-b73c-120367c4e3dd/original.jpeg',
//       original_aspect_ratio: 1.7777777777777777
//     },
//     name: "Grbin: 'Turudić će dobiti nogu u gu*icu kao i Plenković'",
//     id: '2c08f364-c5b8-11ee-a1a0-a6a3e3a01370',
//     state: 'published',
//     type: 'video',
//     published_at: 2024-02-07T12:56:42.000Z,
//     slug: 'grbin-turudic-ce-dobiti-nogu-u-gu-icu-kao-i-plenkovic',
//     original_url: 'https://d20lr2ntorbqvd.cloudfront.net/2c08f364-c5b8-11ee-a1a0-a6a3e3a01370/original',
//     sitemap: {
//       href: '/video/grbin-turudic-ce-dobiti-nogu-u-gu-icu-kao-i-plenkovic-2c08f364-c5b8-11ee-a1a0-a6a3e3a01370',
//       node: [Object]
//     },
//     tags: [ [Object], [Object], [Object] ],
//     prevNext: { prev: [Object], next: [Object] }
//   }
type VideoElastic = {
    extended_attributes: {
        enable_comments: boolean
        display_ads: boolean
        subtitle: string
    }
    image: ImageElastic
    name: string
    published_at: string
    tags: any[]
    prevNext: {
        prev: any
        next: any
    }
    original_url: string
    sitemap: {
        href: string
        node: SitemapNode
    }
}
export type VideoEntityElastic = MergeTypes<MergeTypes<EntityElastic, VideoElastic>, { type: 'video' }>

export const singleEntities = [
    'article',
    'column',
    'counseling',
    'gallery',
    'giveaway',
    'movie',
    'product',
    'recipe',
    'show',
    'video',
    'image-article' // hot-or-not, hunk-of-the-day
] as const
export const listingEntities = [
    'article-listing',
    'author-listing',
    'author',
    'category-archive',
    'category',
    'columnist-listing',
    'columnist',
    'counseling-listing',
    'gallery-listing',
    'giveaway-listing',
    'image-article-listing',
    'image-article-score',
    'latest',
    'product-listing',
    'recipe-listing',
    'tag',
    'video-listing'
] as const

export const generalEntityTypes = ['general', 'product-reviews', 'product-scores', 'search', 'sitemap'] as const

export type EntityType =
    | 'default'
    | (typeof singleEntities)[number]
    | (typeof listingEntities)[number]
    | (typeof generalEntityTypes)[number]
    | 'loading'

export const isSingleEntity = (type: EntityType) => (singleEntities as unknown as EntityType[]).includes(type)
export const isListingEntity = (type: EntityType) => (listingEntities as unknown as EntityType[]).includes(type)
export const isGeneralEntity = (type: EntityType) => (generalEntityTypes as unknown as EntityType[]).includes(type)

export type GeneralType = 'general' | 'single' | 'listing'

export const entityTypeToGeneralType = (type: EntityType) => {
    if (isSingleEntity(type)) return 'single'
    if (isListingEntity(type)) return 'listing'
    return 'general'
}

// ----
// The code bellow is use in getOneById
// ----

type ElasticEntityCommon = {
    id: string
    taxons: {
        'article-type':
            | undefined
            | {
                  slug: string
              }[]
        sitemap: TaxonSitemap[]
    }
    has_children: boolean
    children: any
}

type ElasticArticle = ElasticEntityCommon & {
    type: 'article'
}

type ElasticSitemap = ElasticEntityCommon & {
    type: 'sitemap'
}

type ElasticShow = ElasticEntityCommon & {
    type: 'show'
}

type ElasticVideo = ElasticEntityCommon & {
    type: 'video'
    original_url: string
    custom_manifest_url: string
}

type ElasticUser = ElasticEntityCommon & {
    type: 'user'
}

type ElasticTaxon = ElasticEntityCommon & {
    type: 'taxon'
}

export type ElasticEntity = {
    found: boolean
    _source: ElasticArticle | ElasticSitemap | ElasticShow | ElasticVideo | ElasticUser | ElasticTaxon
}
