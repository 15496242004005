import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

// import { useLiveIzboriData } from '../../../helpers/useLiveIzboriData'
// import predsjednicki from '../../../sample-data/izbori2024/PREDSJ_Ankete.json'
import styles from '../styles/predsjAnkete.style'
import CandidateView from './CandidateView.component'
import { usePredsjednickiIzboriContext } from './PredsjIzboriContext'
import { resultsPropTypes } from './resultsPropTypes'

const IzboriLayout = styled.div(props => ({ ...styles(props) }))

const capitalizeEachWord = str => str.replace(/\b\w/g, char => char.toUpperCase())

export function PredsjIzboriAnkete({ landing, results }) {
    const precomputedCandidates = useMemo(
        () =>
            results?.jedinica[0]?.lista.map(candidate => ({
                ...candidate,
                formattedPosto: candidate.posto ? candidate.posto.replace(',', '.') : '0', // toFixed working strange
                naziv: capitalizeEachWord(candidate.naziv.toLowerCase())
            })) || [],
        [results?.jedinica]
    )

    const favorites = useMemo(() => precomputedCandidates.slice(0, 2), [precomputedCandidates])

    const headContent = (
        <>
            <Image
                src="/images/izbori/pr-izbori-logo-2025.png"
                width="100"
                height="42"
                alt="Predsjednički izbori ankete 2025"
                className="izbori__head_img"
            />
            <div className="izbori__head_info">
                <p className="title">Izlazne ankete</p>
                <p className="source">
                    <span>
                        {/* Izvor: <strong>Ipsos Puls</strong> */}
                        Izvor: <strong>Ipsos</strong>
                    </span>
                    {/* <span>
                            Obrađeno = <strong>{results?.bmPosto}%</strong>
                        </span> */}
                </p>
            </div>
        </>
    )

    return (
        <IzboriLayout className={landing ? 'izbori' : 'izbori is_landing'}>
            {landing ? (
                <Link href={landing} target="_blank" title="Predsjednički izbori 2025" className="izbori__head">
                    {headContent}
                </Link>
            ) : (
                <div className="izbori__head">{headContent}</div>
            )}
            <div className="izbori__cards">
                {/* Favorites */}
                <div className="izbori__favorites">
                    {favorites.map(candidate => (
                        <CandidateView key={candidate.jedinstvenaSifra} candidate={candidate} isSmall={false} />
                    ))}
                </div>
            </div>
        </IzboriLayout>
    )
}

PredsjIzboriAnkete.propTypes = {
    landing: PropTypes.string,
    results: resultsPropTypes.isRequired
}

// const CLIENTSIDE_ENDPOINT = '/api/pr2024/izbori/ankete'

export function PredsjIzboriAnketeWithData({ requestEnabled, landing }) {
    const contextResults = usePredsjednickiIzboriContext()
    const pollData = contextResults.poll

    const memoizedPollData = useMemo(() => {
        if (!pollData?.jedinica?.length) {
            return null
        }
        return pollData
    }, [pollData])

    if (!memoizedPollData) {
        return null
    }

    return <PredsjIzboriAnkete results={memoizedPollData} landing={landing} />
}

PredsjIzboriAnketeWithData.propTypes = {
    requestEnabled: PropTypes.bool,
    landing: PropTypes.string.isRequired
}

PredsjIzboriAnketeWithData.defaultProps = {
    requestEnabled: true
}
