import React from 'react'
import styled from '@emotion/styled'
import Link from 'next/link'
import PropTypes from 'prop-types'

import { useVoyoData } from '@hmn/rtl-web-core/selectors/ads'

import styles from '../styles/predsjKandidati.style'

const IzboriLayout = styled.div(props => ({ ...styles(props) }))

export function PredsjIzboriKandidati({ landingUrl, id, ImageComponent }) {
    const voyoData = useVoyoData()
    const { slides, buttonUrl } = voyoData[id]

    return (
        <IzboriLayout className="izbori">
            <Link href={buttonUrl} target="_blank" title="Predsjednički izbori 2024" className="izbori__head">
                <img
                    src="/images/izbori/pr-izbori-logo.png"
                    width="100"
                    height="42"
                    alt="Predsjednički izbori 2024"
                    className="izbori__head_img"
                />
                <div className="izbori__head_info">
                    <p className="title">Predsjednički izbori</p>
                </div>
            </Link>

            <div className="izbori__cards">
                {slides.map(slide => (
                    <Link
                        className="izbori__card"
                        key={slide.id}
                        href={slide.url || '/'}
                        target="_blank"
                        title={slide.title}>
                        <div className="izbori__card_img" style={{ backgroundColor: slide.subtitle }}>
                            <ImageComponent
                                image={slide.image.id}
                                width="250"
                                height="280"
                                alt={slide.title}
                                className="izbori__image"
                                original
                                lazyLoad={false}
                            />
                        </div>
                        <div className="izbori__card_info">
                            <p className="name">{slide.title}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </IzboriLayout>
    )
}

PredsjIzboriKandidati.propTypes = {
    landingUrl: PropTypes.string,
    // slides: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    //         image: PropTypes.shape({
    //             id: PropTypes.string.isRequired
    //         }).isRequired,
    //         title: PropTypes.string.isRequired
    //     })
    // ),
    id: PropTypes.string.isRequired,
    ImageComponent: PropTypes.elementType
}

PredsjIzboriKandidati.defaultProps = {
    landingUrl: ''
}
