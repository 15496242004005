import PropTypes from 'prop-types'

export const resultsPropTypes = PropTypes.shape({
    izbori: PropTypes.string,
    datum: PropTypes.string,
    vrijeme: PropTypes.string,
    bmUkupno: PropTypes.number,
    bmPosto: PropTypes.string,
    biraciGlasovaloPosto: PropTypes.string,
    jedinica: PropTypes.arrayOf(
        PropTypes.shape({
            sifra: PropTypes.string,
            bmZatvoreno: PropTypes.number,
            biraciUkupno: PropTypes.number,
            biraciGlasovalo: PropTypes.number,
            biraciGlasovaloPosto: PropTypes.string,
            lista: PropTypes.arrayOf(
                PropTypes.shape({
                    rbr: PropTypes.number,
                    jedinstvenaSifra: PropTypes.number,
                    glasova: PropTypes.number,
                    naziv: PropTypes.string,
                    posto: PropTypes.string
                })
            )
        })
    )
})
