const styles = ({ theme, partiesList }) => ({
    '&.izbori': {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxWidth: '1160px',
        margin: '0 -16px',
        padding: '0',
        gridColumnStart: 1,
        gridColumnEnd: 25,

        '@media (min-width: 768px)': {
            width: '100%',
            margin: '0 auto',
            borderRadius: '10px 10px 0 0'
        },

        '&.is_landing': {
            margin: '0',
            maxWidth: '100%'
        },

        '.izbori': {
            '&__head': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                background: 'linear-gradient(45deg, rgba(14,99,173,1) 0%, rgba(18,152,242,1) 100%)',

                '@media (min-width: 768px)': {
                    padding: '8px'
                },

                '&_img': {
                    width: '80px',
                    height: 'auto',

                    '@media (min-width: 768px)': {
                        width: '100px',
                        height: 'auto'
                    }
                },

                '&_info': {
                    textAlign: 'right',

                    '@media (min-width: 768px)': {
                        textAlign: 'right'
                    },

                    '.title, .source': {
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '18px',
                        letterSpacing: '-0.03em',
                        color: '#FFFFFF',
                        textTransform: 'uppercase',
                        fontStyle: 'normal',

                        '@media (min-width: 768px)': {
                            fontSize: '28px',
                            lineHeight: '17px',

                            '&.source': {
                                marginBottom: '-6px',
                                paddingTop: '4px'
                            }
                        }
                    },

                    '.source': {
                        fontSize: '12px',
                        textTransform: 'none',

                        span: {
                            display: 'inline-block',
                            paddingLeft: '8px'
                        }
                    }
                }
            },

            '&__cards': {
                display: 'flex',
                flexDirection: 'column',
                padding: '8px',
                gap: '10px',
                width: '100%',

                '@media (min-width: 768px)': {
                    padding: '8px 0',
                    flexDirection: 'row'
                }
            },

            '&__card': {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',

                '&:hover': {}
            },

            '&__card_img': {
                position: 'relative',
                width: '100%',
                height: 'auto',
                borderRadius: '6px',
                overflow: 'hidden',
                transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    opacity: 0.5,
                    backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)'
                },

                '&:hover': {},

                '&.is_zoran-milanovi': {
                    backgroundColor: '#FE3641'
                },

                '&.is_dragan-primorac': {
                    backgroundColor: '#00BFF8'
                }
            },

            '&__card_perc': {
                position: 'absolute',
                top: '30%',
                left: '5%',
                width: '90%',
                fontSize: '32px', // 38px by design
                fontWeight: '700',
                color: '#FFFFFF',
                textAlign: 'right',
                textShadow: '4px 4px 4px rgba(0, 0, 0, 0.29), 2px -1px 3px rgba(0, 0, 0, 0.5)',

                '@media (min-width: 768px)': {
                    top: '8%',
                    fontSize: '100px',
                    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.29), 2px -1px 3px rgba(0, 0, 0, 0.5)'
                },

                small: {
                    fontSize: '60%',
                    fontWeight: '800'
                }
            },

            '&__card_info': {
                width: '100%',
                marginTop: '5px',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '100%',
                letterSpacing: '-0.03em',
                color: '#080808',
                textAlign: 'left',

                '@media (min-width: 768px)': {
                    marginTop: '8px',
                    fontSize: '20px'
                }
            },

            '&__favorites': {
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                flexWrap: 'nowrap',
                flex: '0 0 100%',

                '.izbori__card': {
                    flex: '1 0 calc(50% - 8px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                },

                '.izbori__card_img': {
                    width: '100%',
                    height: '130px',
                    borderRadius: '6px',

                    '@media (min-width: 768px)': {
                        height: '208px'
                    },

                    '> img': {
                        position: 'absolute',
                        left: '-40px',
                        height: 'auto',

                        '@media (min-width: 768px)': {
                            left: '-50px'
                        }
                    },

                    '&.is_zoran-milanovi > img': {
                        width: '130px',
                        bottom: '-45px',

                        '@media (min-width: 768px)': {
                            width: '290px',
                            bottom: '-140px'
                            // left: '-100px'
                        }
                    },

                    '&.is_dragan-primorac > img': {
                        width: '120px',
                        bottom: '-35px',

                        '@media (min-width: 768px)': {
                            bottom: '-120px',
                            width: '270px'
                        }
                    }
                },

                '.izbori__card_info': {
                    textTransform: 'capitalize',
                    paddingBottom: '10px'
                },

                '.izbori__card:nth-of-type(2)': {
                    img: {
                        right: '-40px',
                        left: 'auto',

                        '@media (min-width: 768px)': {
                            right: '-50px'
                        }
                    },

                    '.izbori__card_perc': {
                        textAlign: 'left'
                    },

                    '.izbori__card_info': {
                        textAlign: 'right'
                    }
                }
            }
        }
    }
})

export default styles
