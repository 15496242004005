/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const GradoviMap = React.memo(({ activeCity, onCityClick }) => {
    const handleClick = event => {
        const cityId = event.target.closest('g').id
        onCityClick(cityId)
    }

    const cities = [
        { id: 'city-3123', cx: 469, cy: 128, textX: 401, textY: 134, name: 'Osijek' },
        { id: 'city-5185', cx: 496, cy: 155, textX: 414, textY: 161, name: 'Vukovar' },
        { id: 'city-3964', cx: 410, cy: 176, textX: 332, textY: 181, name: 'Sl. Brod' },
        { id: 'city-3590', cx: 34, cy: 212, textX: 22, textY: 201, name: 'Pula' },
        { id: 'city-1333', cx: 226, cy: 99, textX: 196, textY: 125, name: 'Zagreb' },
        { id: 'city-3735', cx: 89, cy: 153, textX: 99, textY: 159, name: 'Rijeka' },
        { id: 'city-4090', cx: 272, cy: 385, textX: 282, textY: 392, name: 'Split' },
        { id: 'city-3891', cx: 286, cy: 362, textX: 240, textY: 367, name: 'Sinj' },
        { id: 'city-5207', cx: 162, cy: 314, textX: 171, textY: 319, name: 'Zadar' },
        { id: 'city-0981', cx: 423, cy: 498, textX: 431, textY: 489, name: 'Dubrovnik' },
        { id: 'city-4723', cx: 257, cy: 33, textX: 217, textY: 60, name: 'Varaždin' }
    ]

    return (
        <svg
            width="541"
            height="529"
            viewBox="0 0 541 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="CityMap">
            {cities.map(city => (
                <g
                    key={city.id}
                    id={city.id}
                    className={`city-group ${activeCity === city.id ? 'is_active' : ''}`}
                    onClick={handleClick}>
                    <title>{city.name}</title>
                    <circle
                        cx={city.cx}
                        cy={city.cy}
                        r="6"
                        fill={city.name === 'Dubrovnik' ? '#383838' : 'white'}
                        stroke="#383838"
                        className={`city ${activeCity === city.id ? 'is_active' : ''}`}
                    />
                    <text
                        x={city.textX}
                        y={city.textY}
                        fill={city.name === 'Dubrovnik' ? '#383838' : 'white'}
                        className={`city-text ${activeCity === city.id ? 'is_active' : ''}`}>
                        {city.name}
                    </text>
                </g>
            ))}
        </svg>
    )
})

GradoviMap.propTypes = {
    activeCity: PropTypes.string.isRequired,
    onCityClick: PropTypes.func.isRequired
}
