import { createContext, useContext } from 'react'

type PredsjednickiIzboriData = {
    counties: any
    cities: any
    poll: any
}

const defaultValue: PredsjednickiIzboriData = {
    counties: null,
    cities: null,
    poll: null
}

const PredsjednickiIzboriContext = createContext<PredsjednickiIzboriData>(defaultValue)

export const PredsjednickiIzboriContextProvider = PredsjednickiIzboriContext.Provider

export const usePredsjednickiIzboriContext = () => useContext(PredsjednickiIzboriContext)
