/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'

import { FilledSlot, useFilledSlots } from '../../../context/ads/filled.context'
import {
    ClientNavigationState,
    useClientNavigationState,
    useInarticleAdMountedDispatch
} from '../../../context/clientNavigation'
import { checkConfig } from '../../../helpers/ads/checkConfig'
import getSlotDivId from '../../../helpers/ads/getSlotDivId'
import { SlotRenderAnchor } from '../../../hooks/ads/useExistingSlots'
import useDeviceType from '../../../hooks/layout/useDeviceType'
import { MergeTypes } from '../../../types/helpers/MergeTypes'
import { withErrorBoundary } from '../../ErrorBoundary/ErrorBoundary.component'

export type OnAdChangeCallback = (filledSlot?: FilledSlot) => void

export const slotDeviceConfigPropTypes = PropTypes.shape({
    adUnit: PropTypes.string,
    divId: PropTypes.string,
    enabled: PropTypes.bool,
    fixedHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lazyLoad: PropTypes.bool,
    outOfPage: PropTypes.bool,
    sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    zoneName: PropTypes.string
})

export const sharedSlotPropTypes = {
    targetingArguments: PropTypes.oneOfType([() => null, PropTypes.object]),
    className: PropTypes.string,
    isWidgetBanner: PropTypes.bool,
    maxAdWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    alternateBg: PropTypes.bool,
    isInarticle: PropTypes.bool
}
const slotPropTypes = {
    ...sharedSlotPropTypes,
    outOfPage: PropTypes.bool,
    onAdChange: PropTypes.func,
    config: PropTypes.shape({
        config: slotDeviceConfigPropTypes,
        mobile: slotDeviceConfigPropTypes,
        desktop: slotDeviceConfigPropTypes,
        desktopAdZone: PropTypes.string,
        mobileAdZone: PropTypes.string,
        isValid: PropTypes.bool,
        hasDesktopConfig: PropTypes.bool,
        hasMobileConfig: PropTypes.bool
    })
}

type SlotProps = MergeTypes<
    PropTypes.InferProps<typeof slotPropTypes>,
    {
        onAdChange?: OnAdChangeCallback
    }
>
const Slot = ({ className, onAdChange, isInarticle, config }: SlotProps) => {
    const [deviceType] = useDeviceType()

    const { config: deviceConfig, hasMobileConfig, hasDesktopConfig } = config || {}

    const setInarticleAdMounted = useInarticleAdMountedDispatch()

    const navigationState = useClientNavigationState()
    useEffect(() => {
        if (isInarticle && navigationState === ClientNavigationState.READY) {
            setInarticleAdMounted(true)
        }
    }, [navigationState, isInarticle])

    const [filledSlots] = useFilledSlots()
    useEffect(() => {
        if (typeof onAdChange !== 'function') return
        const thisSlotDivId = getSlotDivId(deviceConfig)
        if (thisSlotDivId) {
            onAdChange(filledSlots[thisSlotDivId])
        }
    }, [filledSlots, deviceConfig, onAdChange])

    if (!(hasMobileConfig || hasDesktopConfig) || (deviceType && !checkConfig(deviceConfig)) || !deviceConfig?.divId) {
        return null
    }

    return (
        <>
            <div
                className={clsx(
                    'adunitContainer',
                    className,
                    deviceConfig.adUnit,
                    deviceConfig.zoneName?.replaceAll(' ', '_')
                )}
                id={deviceConfig.divId}
            />
            <SlotRenderAnchor divId={deviceConfig.divId} />
        </>
    )
}

Slot.propTypes = slotPropTypes

export const sharedSlotDefaultProps = {
    targetingArguments: undefined,
    onAdChange: undefined,
    className: undefined,
    isWidgetBanner: false,
    maxAdWidth: undefined,
    alternateBg: false,
    isInarticle: false
}

Slot.defaultProps = {
    ...sharedSlotDefaultProps,
    outOfPage: false,
    config: undefined
}
export default withErrorBoundary(memo(Slot), {
    FallbackComponent: () => null,
    onError: (error, componentStack) => {
        // eslint-disable-next-line no-console
        console.error('[AdSlot]: ', error, componentStack)
    }
})
