import React from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { useActiveCategory, useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../../../../../../../ConditionalWrapper'
import { PrimaryMenuItem, SecondaryMenuItem } from '..'
import styles, { itemMotionVariants, panelMotionVariants } from './Animated.style'
import { MenuArrow } from './components/MenuArrow'

const AnimatedStyled = styled.div(props => ({ ...styles(props) }))

function Animated({ className, link, variant, gtmData, clickFunction, open, higlightActive, ...rest }) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const [activeCategory, activeSubcategory] = useActiveCategory()
    const { title, href, items, color, className: linkClassName = '', image } = link || {}

    if (!link || !title) {
        return null
    }

    const primaryIsActive =
        higlightActive &&
        ((activeSubcategory ?? activeCategory) === href ||
            (variant === 'navigation' && isDesktop) ||
            variant === 'footer')

    const secondaryIsActive = higlightActive && variant === 'navigation'

    const isAnimated = ['navigation', 'footer'].some(v => v === variant) && !isDesktop

    if (typeof image === 'string') {
        return (
            <AnimatedStyled className={className} variant={variant} {...rest}>
                <PrimaryMenuItem
                    className="Animated_primaryItem"
                    variant={variant}
                    title={title}
                    href={href}
                    gtmData={gtmData}
                    categoryColor={color}
                    active={primaryIsActive}
                    image={image}
                    imageClassName={linkClassName}
                />
            </AnimatedStyled>
        )
    }

    return (
        <AnimatedStyled className={className} variant={variant} {...rest}>
            {isAnimated && (
                <div className="Animated_left">
                    {items?.length > 0 && (
                        <MenuArrow
                            title={title}
                            className="Animated_expandButton"
                            open={open}
                            onClick={() => {
                                if (clickFunction) {
                                    clickFunction()
                                }
                            }}
                        />
                    )}
                </div>
            )}
            <ConditionalWrapper condition={isAnimated} Wrapper="div" className="Animated_right">
                <PrimaryMenuItem
                    className="Animated_primaryItem"
                    variant={variant}
                    title={title}
                    href={href}
                    onClick={
                        !href
                            ? () => {
                                  if (clickFunction) {
                                      clickFunction()
                                  }
                              }
                            : undefined
                    }
                    gtmData={gtmData}
                    categoryColor={color}
                    active={primaryIsActive}
                />
                {['navigation', 'footer'].some(v => v === variant) && items?.length > 0 && (
                    <ConditionalWrapper
                        condition={!isDesktop}
                        Wrapper={motion.div}
                        initial="closed-menu-item"
                        animate={open ? 'open-menu-item' : 'closed-menu-item'}
                        variants={panelMotionVariants}
                        className="Animated_item_wrapper">
                        {items.map(({ title: subTitle, href: subHref }, index) => (
                            <ConditionalWrapper
                                condition={!isDesktop}
                                Wrapper={motion.div}
                                variants={itemMotionVariants}
                                key={uid(`wrapper${index}`)}>
                                <SecondaryMenuItem
                                    key={uid(`${subHref || subTitle}${index}`)}
                                    className="Animated_secondaryItem"
                                    variant={variant}
                                    title={subTitle}
                                    href={subHref}
                                    gtmData={gtmData}
                                    color={color}
                                    active={
                                        secondaryIsActive &&
                                        [activeCategory, activeSubcategory].some(
                                            c => c === (subHref || `/${subTitle.toLowerCase()}`)
                                        )
                                    }
                                />
                            </ConditionalWrapper>
                        ))}
                    </ConditionalWrapper>
                )}
            </ConditionalWrapper>
        </AnimatedStyled>
    )
}

const animatedMenuItemVariants = Object.freeze({
    HEADER: 'header',
    NAVIGATION: 'navigation',
    FOOTER: 'footer',
    INFO: 'info',
    PARTNER: 'partner'
})

Animated.propTypes = {
    className: PropTypes.string,
    link: PropTypes.oneOfType([() => null, PropTypes.object]),
    variant: PropTypes.oneOf([...Object.values(animatedMenuItemVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    higlightActive: PropTypes.bool,
    open: PropTypes.bool,
    clickFunction: PropTypes.func
}

Animated.defaultProps = {
    className: undefined,
    link: undefined,
    variant: animatedMenuItemVariants.HEADER,
    gtmData: undefined,
    higlightActive: true,
    open: false,
    clickFunction: undefined
}

export { animatedMenuItemVariants }
export default Animated
