import { toRem, toRems } from '../../../../helpers'
import { menuVariants } from './Menu.variants'

const { HEADER, PARTNER, INFO } = menuVariants

const isVariant = (variant, ...variants) => variants.some(v => v === variant)

const style = ({ theme, variant }) => ({
    overflow: 'hidden',
    letterSpacing: 'initial',
    '.predsjednicki_izbori_button': {
        width: '260px',
        margin: '-5px 0',
        [theme.breakpoints.up('md')]: {
            width: '230px',
            margin: '-4px 0 0'
        }
    },
    '.rukomet_button': {
        width: '20px',
        height: 'auto',
        margin: '-3px 0 0 4px'
    },
    '.Menu': {
        '&_container': {
            display: 'flex',

            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexDirection: isVariant(variant, PARTNER, INFO) ? 'row' : 'column',
            ...(isVariant(variant, HEADER) && {
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                paddingLeft: toRem(15)
            }),
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        '&_group': {
            display: 'flex',
            flexDirection: isVariant(variant, HEADER, PARTNER, INFO) ? 'row' : 'column',
            flexWrap: isVariant(variant, HEADER, PARTNER, INFO) ? 'wrap' : 'initial',
            height: isVariant(variant, HEADER) ? '100%' : 'auto'
        },
        '&_item': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 auto',
                margin: toRems([0, isVariant(variant, HEADER) ? 0 : 5])
            }
        },
        '&_title': {
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: toRem(14),
            textTransform: 'uppercase',
            color: theme.colors.navigation?.menu?.title || theme.colors.devWarning,
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 5])
            }
        }
    }
})

export default style
